<template>
  <div class="data-details__map">
    <div class="d-flex info-event">
      <div class="info h-100 w-100">
        <div class="click-area" @click="$emit('click-map-area')" />
        <div v-if="showDetails" class="info-items">
          <p>
            <component
              class="title"
              :is="entity.web_site ? 'a' : 'span'"
              :href="entity.web_site"
              :target="entity.web_site ? '_blank' : null"
              :rel="entity.web_site ? 'noopener nofollow' : null"
              >{{ event.title }}</component
            >
          </p>
          <p class="address">{{ entity.address }}</p>
          <p class="phone">{{ entity.phone }}</p>
        </div>
      </div>
      <div class="w-100">
        <MapWithEntityDestination :latitude="latitude" :longitude="longitude" :zoom="zoom" />
      </div>
    </div>
  </div>
</template>

<script>
import MapWithEntityDestination from '@/components/partials/MapWithEntityDestination.vue';

export default {
  name: 'EntityDetailsMap',
  components: { MapWithEntityDestination },
  props: {
    event: {
      type: Object,
      required: true,
    },
    showDetails: {
      type: Boolean,
      default: true,
    },
    zoom: {
      type: Number,
      default: 13,
    },
  },
  computed: {
    entity() {
      return this.event?.artSpace || this.event;
    },
    latitude() {
      return parseFloat(this.entity.latitude);
    },
    longitude() {
      return parseFloat(this.entity.longitude);
    },
  },
};
</script>
